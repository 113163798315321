import { QueryClient } from "@tanstack/react-query"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      gcTime: 1000 * 60 * 60, // 1 hours
      staleTime: 1000 * 60, // 1 minutes
      throwOnError: true,
    },
  },
})

export default queryClient
