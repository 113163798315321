import { createFileRoute } from "@tanstack/react-router"

import NotFoundPage from "@/app/utils/404"

export const Route = createFileRoute("/_utils/404")({
  component: RouteComponent,
})

function RouteComponent() {
  return <NotFoundPage />
}
