import { createFileRoute, useNavigate } from "@tanstack/react-router"

import { clearCookie } from "@sap/utils/cookies"

import { ENVIRONMENT } from "@/config"

export const Route = createFileRoute("/_auth/auth/logout")({
  component: LogoutComponent,
})

function LogoutComponent() {
  const navigate = useNavigate()

  clearCookie(`access_token_${ENVIRONMENT}`)
  clearCookie(`access_token`)

  navigate({
    to: "/auth/login",
    replace: true,
  })

  return <></>
}
