import { Provider } from "@rollbar/react"
import RollbarInit from "rollbar"

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_CLIENT_TOKEN || "xxx",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.VITE_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.VITE_GIT_SHA,
      },
    },
    server: {
      branch: import.meta.env.VITE_APP_BRANCH,
    },
  },
  enabled: import.meta.env.PROD,
}

// eslint-disable-next-line react-refresh/only-export-components
export const rollbar = new RollbarInit(rollbarConfig)

export default function RollbarProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return <Provider instance={rollbar}>{children}</Provider>
}
