import axios from "axios"

import { BACKEND_URL } from "@/config"

export const axiosUnauthenticated = axios.create({
  baseURL: BACKEND_URL,
  paramsSerializer: {
    indexes: null,
  },
})

axiosUnauthenticated.interceptors.request.use(async function (config) {
  config.headers["Accept-Language"] = "nl"
  return config
})
