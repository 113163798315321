import type { AuthUri, Tokens } from "@/types/api/user"

import { axiosUnauthenticated } from "./axios"
import { handleError } from "./utils"

export async function authUrl(provider: string) {
  return axiosUnauthenticated.get<AuthUri>(`/user/social/${provider}/auth-uri/`).then((res) => {
    return res.data
  })
}

export async function callback(provider: string = "sat-azure-tenant", code: string, host?: string) {
  return axiosUnauthenticated
    .get<Tokens>(`/user/social/${provider}/callback/`, {
      params: { code, host },
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw handleError(err)
    })
}
