import { createFileRoute, stripSearchParams } from "@tanstack/react-router"
import { fallback, zodValidator } from "@tanstack/zod-adapter"
import { z } from "zod"

import { authUriQueryOptions } from "@/api/queryOptions"
import LoginPage from "@/app/auth/login/page"

// eslint-disable-next-line react-refresh/only-export-components
export const schema = z.object({
  next: fallback(z.string(), "").default(""),
  path: fallback(z.string().startsWith("/"), "").default(""),
})

export type Search = z.infer<typeof schema>

export const Route = createFileRoute("/_auth/auth/login")({
  component: LoginComponent,
  validateSearch: zodValidator(schema),
  search: {
    middlewares: [
      stripSearchParams({
        next: "",
        path: "",
      }),
    ],
  },
  loader: async ({ context: { queryClient } }) => {
    queryClient.prefetchQuery(authUriQueryOptions())
    return
  },
})

function LoginComponent() {
  return <LoginPage />
}
