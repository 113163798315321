import { QueryClientProvider } from "@tanstack/react-query"
import type { QueryClient } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ErrorComponent, Outlet, createRootRouteWithContext } from "@tanstack/react-router"
import { Suspense, lazy } from "react"

import queryClient from "@/api/queryclient"
import NotFoundPage from "@/app/utils/404"
import ErrorPage from "@/app/utils/ErrorPage"
import LoadingComponent from "@/components/layout/loading"
import RollbarProvider from "@/utils/rollbar"

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      )

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: RootComponent,
  errorComponent: ({ error }) =>
    process.env.NODE_ENV !== "development" ? (
      <ErrorPage error={error} />
    ) : (
      <ErrorComponent error={error} />
    ),
  pendingComponent: () => <LoadingComponent />,
  notFoundComponent: () => <NotFoundPage />,
})

export function RootComponent() {
  return (
    <>
      <RollbarProvider>
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </QueryClientProvider>
      </RollbarProvider>
      <Suspense fallback={null}>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
    </>
  )
}
