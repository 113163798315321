/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'
import { Route as UtilsLoadingImport } from './routes/_utils/loading'
import { Route as Utils404Import } from './routes/_utils/404'
import { Route as AuthAuthLogoutImport } from './routes/_auth/auth/logout'
import { Route as AuthAuthLoginImport } from './routes/_auth/auth/login'
import { Route as AuthAuthCallbackProviderImport } from './routes/_auth/auth/callback.$provider'

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const UtilsLoadingRoute = UtilsLoadingImport.update({
  id: '/_utils/loading',
  path: '/loading',
  getParentRoute: () => rootRoute,
} as any)

const Utils404Route = Utils404Import.update({
  id: '/_utils/404',
  path: '/404',
  getParentRoute: () => rootRoute,
} as any)

const AuthAuthLogoutRoute = AuthAuthLogoutImport.update({
  id: '/auth/logout',
  path: '/auth/logout',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAuthLoginRoute = AuthAuthLoginImport.update({
  id: '/auth/login',
  path: '/auth/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAuthCallbackProviderRoute = AuthAuthCallbackProviderImport.update({
  id: '/auth/callback/$provider',
  path: '/auth/callback/$provider',
  getParentRoute: () => AuthRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_utils/404': {
      id: '/_utils/404'
      path: '/404'
      fullPath: '/404'
      preLoaderRoute: typeof Utils404Import
      parentRoute: typeof rootRoute
    }
    '/_utils/loading': {
      id: '/_utils/loading'
      path: '/loading'
      fullPath: '/loading'
      preLoaderRoute: typeof UtilsLoadingImport
      parentRoute: typeof rootRoute
    }
    '/_auth/auth/login': {
      id: '/_auth/auth/login'
      path: '/auth/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthAuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/auth/logout': {
      id: '/_auth/auth/logout'
      path: '/auth/logout'
      fullPath: '/auth/logout'
      preLoaderRoute: typeof AuthAuthLogoutImport
      parentRoute: typeof AuthImport
    }
    '/_auth/auth/callback/$provider': {
      id: '/_auth/auth/callback/$provider'
      path: '/auth/callback/$provider'
      fullPath: '/auth/callback/$provider'
      preLoaderRoute: typeof AuthAuthCallbackProviderImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthAuthLoginRoute: typeof AuthAuthLoginRoute
  AuthAuthLogoutRoute: typeof AuthAuthLogoutRoute
  AuthAuthCallbackProviderRoute: typeof AuthAuthCallbackProviderRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAuthLoginRoute: AuthAuthLoginRoute,
  AuthAuthLogoutRoute: AuthAuthLogoutRoute,
  AuthAuthCallbackProviderRoute: AuthAuthCallbackProviderRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteWithChildren
  '/404': typeof Utils404Route
  '/loading': typeof UtilsLoadingRoute
  '/auth/login': typeof AuthAuthLoginRoute
  '/auth/logout': typeof AuthAuthLogoutRoute
  '/auth/callback/$provider': typeof AuthAuthCallbackProviderRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/404': typeof Utils404Route
  '/loading': typeof UtilsLoadingRoute
  '/auth/login': typeof AuthAuthLoginRoute
  '/auth/logout': typeof AuthAuthLogoutRoute
  '/auth/callback/$provider': typeof AuthAuthCallbackProviderRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_utils/404': typeof Utils404Route
  '/_utils/loading': typeof UtilsLoadingRoute
  '/_auth/auth/login': typeof AuthAuthLoginRoute
  '/_auth/auth/logout': typeof AuthAuthLogoutRoute
  '/_auth/auth/callback/$provider': typeof AuthAuthCallbackProviderRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/404'
    | '/loading'
    | '/auth/login'
    | '/auth/logout'
    | '/auth/callback/$provider'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/404'
    | '/loading'
    | '/auth/login'
    | '/auth/logout'
    | '/auth/callback/$provider'
  id:
    | '__root__'
    | '/_auth'
    | '/_utils/404'
    | '/_utils/loading'
    | '/_auth/auth/login'
    | '/_auth/auth/logout'
    | '/_auth/auth/callback/$provider'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  Utils404Route: typeof Utils404Route
  UtilsLoadingRoute: typeof UtilsLoadingRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  Utils404Route: Utils404Route,
  UtilsLoadingRoute: UtilsLoadingRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_utils/404",
        "/_utils/loading"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/auth/login",
        "/_auth/auth/logout",
        "/_auth/auth/callback/$provider"
      ]
    },
    "/_utils/404": {
      "filePath": "_utils/404.tsx"
    },
    "/_utils/loading": {
      "filePath": "_utils/loading.tsx"
    },
    "/_auth/auth/login": {
      "filePath": "_auth/auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/auth/logout": {
      "filePath": "_auth/auth/logout.tsx",
      "parent": "/_auth"
    },
    "/_auth/auth/callback/$provider": {
      "filePath": "_auth/auth/callback.$provider.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
