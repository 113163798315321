const ErrorPage = ({ error }: { error: Error }) => {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Error</h1>
        <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
          Sorry, an unexpected error has occurred.
        </p>
        <p className="mt-2 text-sm text-red-500 dark:text-red-400">
          {error.message ? <code>{error.message}</code> : null}
        </p>
      </div>
    </div>
  )
}

export default ErrorPage
