import { queryOptions } from "@tanstack/react-query"

import { authUrl } from "."

export const authUriQueryOptions = (provider: string = "sat-azure-tenant") =>
  queryOptions<{ auth_uri: string }>({
    queryKey: ["auth-uri", provider],
    queryFn: () => authUrl(provider),
    retry: 3,
  })
