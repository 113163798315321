import { type AxiosError, isAxiosError } from "axios"

interface ApiError {
  error_code: string
  error_type: "client_error" | "server_error"
  error_detail?: string
  [key: string]: unknown
}

type ResponseError = ApiError | string

export type ParsedErrorType = ServerError | ClientError

export class ServerError extends Error {
  constructor(
    error: Error,
    public statusCode: number,
    public error_type: string,
    public error_code: string,
    public error_detail: string,
    public field_errors: Record<string, unknown> = {}
  ) {
    super(error_detail)
    this.statusCode = statusCode
    this.error_type = error_type
    this.error_code = error_code
    this.stack = error.stack
    this.field_errors = field_errors
  }
}

export class ClientError extends Error {
  error_type: string
  constructor(
    error: Error,
    public error_detail: string,
    public field_errors: Record<string, unknown> = {}
  ) {
    super(error_detail)
    this.stack = error.stack
    this.error_type = "client_error"
    this.field_errors = field_errors
  }
}

export function handleError(error: Error | AxiosError<ResponseError>): ServerError | ClientError {
  if (isAxiosError(error)) {
    if (!error.response) {
      return new ServerError(error, 500, "server_error", "server_error", error.message)
    }

    if (typeof error.response?.data === "string") {
      return new ServerError(
        error,
        error.response.status,
        "server_error",
        "server_error",
        error.response.data
      )
    }

    const data = error.response?.data as ApiError

    const { error_type, error_code, error_detail, ...field_errors } = data

    return new ServerError(
      error,
      error.response.status,
      error_type,
      error_code,
      error_detail || "",
      field_errors
    )
  } else {
    return new ClientError(error, error.message)
  }
}

export function isBackendError(error: Error | null): error is ParsedErrorType {
  if (error === null) {
    return false
  }
  return error instanceof ServerError || error instanceof ClientError
}
