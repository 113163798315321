import { Link } from "@tanstack/react-router"

import { Button } from "@/components/ui/button"

export default function NotFoundPage() {
  return (
    <div className="flex min-h-[100vh] flex-col items-center justify-center space-y-4 px-4 md:px-6">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
          404 - Page Not Found
        </h1>
        <p className="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
          Don&#x27;t worry, you haven&#x27;t broken the internet. You can find your way back to the
          login page.
        </p>
      </div>
      <Button variant="default" asChild>
        <Link to="/auth/login">Back to login</Link>
      </Button>
    </div>
  )
}
