import { createFileRoute, stripSearchParams } from "@tanstack/react-router"
import { fallback, zodValidator } from "@tanstack/zod-adapter"
import { z } from "zod"

import CallbackPage from "@/app/auth/callback/page"

const schema = z.object({
  code: fallback(z.string(), "").default(""),
  state: fallback(z.string(), "").default(""),
  error: fallback(z.string(), "").default(""),
})

export const Route = createFileRoute("/_auth/auth/callback/$provider")({
  component: CallbackComponent,
  validateSearch: zodValidator(schema),
  search: {
    middlewares: [
      stripSearchParams({
        error: "",
      }),
    ],
  },
})

function CallbackComponent() {
  return <CallbackPage />
}
