import { createFileRoute } from "@tanstack/react-router"

import LoadingComponent from "@/components/layout/loading"

export const Route = createFileRoute("/_utils/loading")({
  component: RouteComponent,
})

function RouteComponent() {
  return <LoadingComponent />
}
